import { makeAutoObservable } from "mobx";
import localStorageStore from "../../stores/localStorageStore";
import componentsStore from "../../stores/componentsStore";
import productsStore from "../../stores/productsStore";

import ApiManager from "../../ApiManager/APIManager";

export const PrStore = class {
  transactionStatusInterval;
  clearTransactionStatusInterval;
  cryptoOrderInterval;
  clearCryptoOrderInterval
  paymentMethod = 'card';
  cryptoPaymentUrl = '';

  createOrder = async () => {
    try {
      let res = await ApiManager.post(`create-order/${productsStore.pointId}`, {"products": productsStore.selectedData ?? []}, {headers: {'Content-Language': localStorageStore.getCurrentLanguage()}});
      let trId = res?.data?.data.transactionId;
      localStorage.setItem("transactionId", trId);
      localStorage.setItem("transactionIdForReceipt", trId);
      
      // eslint-disable-next-line no-restricted-globals
      if (res?.data?.data.checkoutURL) {
        // eslint-disable-next-line no-restricted-globals
        location.href = res?.data?.data.checkoutURL;
      } else {
        componentsStore.changePage(componentsStore.pages.paymentSuccess);
      }
      
    } catch (ex) {
      return false;
    } finally {}
  };

  statusInterval = async (trId) => {
    try {
      var res = await ApiManager.get(`interval/${trId}`, {headers: {'Content-Language': localStorageStore.getCurrentLanguage()}});
      var data = res?.data;
      var second = 0;

      this.transactionStatusInterval = setInterval(() => {
        this.transactionStatus(trId)
      }, data?.maxTimeInSeconds / data?.numberOfTries * 1000);

      this.clearTransactionStatusInterval = setInterval(() => {
        if (second === data?.maxTimeInSeconds) {
          clearInterval(this.transactionStatusInterval);
          clearInterval(this.clearTransactionStatusInterval);
          this.transactionDone("FAILED")
        }
        second++;
      }, 1000);
    } catch (ex) {
      return false;
    } finally {}
  };

  transactionStatus = async (trId) => {
    try {
      var res = await ApiManager.get(`status/${trId}`, {headers: {'Content-Language': localStorageStore.getCurrentLanguage()}});
      var status = res?.data?.data?.status;

      if (status !== "PENDING") {
        localStorage.removeItem("transactionId");
        this.transactionDone(status);
      }
    } catch (ex) {
      return false;
    } finally {}
  };

  // 
  createCryptoOrder = async () => {
    try {
      var res = await ApiManager.post(`create-crypto-order/${productsStore.pointId}`, {"products": productsStore.selectedData ?? []}, {headers: {'Content-Language': localStorageStore.getCurrentLanguage()}});
      var trId = res?.data?.data.transactionId;
      localStorage.setItem("transactionId", trId);
      localStorage.setItem("transactionIdForReceipt", trId);
  
      this.cryptoPaymentUrl = res?.data?.data.checkoutURL;

      var second = 0;

      this.cryptoOrderInterval = setInterval(() => {
        this.cryptoTransactionStatus(trId);
      }, 1000);

      this.clearCryptoOrderInterval = setInterval(() => {
        if (second === 190) {
          clearInterval(this.cryptoOrderInterval);
          clearInterval(this.clearCryptoOrderInterval);
          this.transactionDone("FAILED")
        }
        second++;
      }, 1000);
    } catch (ex) {
      return false;
    } finally {}
  };

  cryptoTransactionStatus = async (trId) => {
    try {
      var res = await ApiManager.get(`status/${trId}`, {headers: {'Content-Language': localStorageStore.getCurrentLanguage()}});
      var status = res?.data?.data?.status;
      
      if (status === "PENDING") {
        componentsStore.changePage(componentsStore.pages.paymentSuccess);
      } else if (status === "COMPLETED" || status === "FAILED") {
        this.transactionDone(status);
      }
    } catch (ex) {
      return false;
    } finally {}
  };
  // 

  transactionDone (status) {
    clearInterval(this.transactionStatusInterval);
    clearInterval(this.clearTransactionStatusInterval);
    clearInterval(this.cryptoOrderInterval);
    clearInterval(this.clearCryptoOrderInterval);
    status === "COMPLETED" 
      ? componentsStore.changePage(componentsStore.pages.success)
      : componentsStore.changePage(componentsStore.pages.error)
  }

  reset () {
    this.paymentMethod = 'card';
    this.cryptoPaymentUrl = ''
  }

  constructor() {
    makeAutoObservable(this);
  }
};

export default new PrStore();


















 