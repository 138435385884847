import React, { useEffect, useRef } from "react";
import { Observer } from "mobx-react";
import { motion } from "framer-motion";
import variants from "./variants";
import componentsStore from "../../stores/componentsStore";
import timeoutsStore from "../../stores/timeoutsStore";
import resetAllStore from "../../stores/resetAllStore";
import { useTranslation } from 'react-i18next';

import MainShape from "../../components/MainShape";
import Button from "../../components/Button";

import smsIconSrc from "../../assets/images/sms-icon.svg";
import styles from "./styles.module.css";

const Success = () => {  
  const dataFetchedRef = useRef(false);
  const { t } = useTranslation();

  useEffect(()=>{
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    timeoutsStore.statusPagesTimeout();
  },[]);

  return (
    <Observer
      render={() => (
        <React.Fragment>
          <motion.div>
            <div className={`page-content`}>
              <main>
                <MainShape fill="#A0D39F" verticalPosition="bottom" horizontalPosition="right" />
                <div className={styles.topBox}>
                  <motion.h2
                    className={`${styles.title} f-lgv`}
                    variants={variants.titleVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                  >
                    {t('success')}
                  </motion.h2>
                  <motion.div
                    className={`${styles.text}`}
                    variants={variants.textVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                  >
                    {t('the-payment-was-successful')}
                  </motion.div>
                  <motion.div
                    className={`${styles.helperText} f-lgv`}
                    variants={variants.helperTextVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                  >
                    {t('collect-your-capsules-in-dropper')}
                  </motion.div>
                  <div className={styles.smsButton}>
                    <Button
                      label={t('get-sms-receipt')}
                      icon={smsIconSrc}
                      animationDelay={0.55} 
                      onClick={() => {
                        timeoutsStore.clearStPagesTimeout();
                        componentsStore.changePage(componentsStore.pages.receipt);
                        timeoutsStore.startReceiptPageTimeout();
                      }} 
                    />
                  </div>
                  <div className={styles.button}>
                    <Button 
                      label={t('home-page')}
                      classText="transparent" 
                      animationDelay={0.55} 
                      onClick={() => resetAllStore.resetAll()} 
                    />
                  </div>
                </div>
              </main>
            </div>
          </motion.div>
        </React.Fragment>
      )}
    />
  );
};

export default Success;
