import React, { useEffect, useRef } from "react";
import { Observer } from "mobx-react";
import { AnimatePresence } from "framer-motion";
import { useTranslation } from 'react-i18next';
import componentsStore from "./stores/componentsStore";
import loaderStore from './stores/loaderStore';
import Header from "./components/Header";
import Loader from "./components/Loader";
import BusyLayer from "./components/BusyLayer";

import "./assets/css/normalize.css";
import "./assets/css/grids.css";
import "./assets/css/styles.css";

const App = () => {
  const { t } = useTranslation();
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    const setWindowHeight = () => document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01 - 0.01}px`);
    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);
    return () => window.removeEventListener("resize", setWindowHeight);
  }, []);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    
    let params = new URLSearchParams(window.location.search);
  
    if (params.get('status') && localStorage.getItem("transactionId")) {
      componentsStore.changePage(componentsStore.pages.paymentSuccess);
    } else {
      localStorage.removeItem("transactionId");
      localStorage.removeItem("transactionIdForReceipt");
      params.delete('status');
      const search = params.toString();
      const url = `${window.location.pathname}?${search}`;
      window.history.replaceState(null, null, url);
    }
  }, []);
  
  return (
    <React.Fragment>
      <div className="page-wrap">
        <Header />
        <Observer render={() => ( <AnimatePresence mode='wait'>{componentsStore.currentComponent}</AnimatePresence> )}/>
        <Observer render={() => ( <BusyLayer key={componentsStore.busy} /> )}/>
        <Observer render={() => ( <AnimatePresence>{loaderStore.loader ? <Loader /> : null}</AnimatePresence> )}/>
      </div>
      <div id="landscapeScreen" className="f-lgv">{t('landscape-version-text')}</div>
    </React.Fragment>
  );
};

export default App;