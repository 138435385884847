import { createContext } from "react";
import { makeAutoObservable } from "mobx";
import localStorageStore from "../../stores/localStorageStore";
import componentsStore from "../../stores/componentsStore";
import loaderStore from "../../stores/loaderStore";

import ApiManager from "../../ApiManager/APIManager";

export const ReceiptStore = class {
  phoneMasksData = [];
  defaultOption = [];

  getPhoneMasks = async () => {
    try {
      var res = await ApiManager.get(`phoneMasks`, {headers: {'Content-Language': localStorageStore.getCurrentLanguage()}});
      res?.data.map(o => {
        this.phoneMasksData.push({ 
          value: o.prefix, 
          label: o.prefix, 
          main: o.main,
          regEx: o.regEx 
        })
      });
      this.defaultOption = this.phoneMasksData.find(o => o.main);
      return this.phoneMasksData, this.defaultOption;
    } catch (ex) {
      return false;
    } finally {}
  };

  sendSMS = async (phone, phoneIndex) => {
    loaderStore.loader = true;
    try {
      var result = await ApiManager.post(`receipt` , {"phone": phone, "phoneIndex": phoneIndex, "transactionId": localStorage.getItem("transactionIdForReceipt")}, {headers: {'Content-Language': localStorageStore.getCurrentLanguage()}});
     
      if(!result || result.data.isError) {
        setTimeout(() => loaderStore.loader = false, 400);
        return;
      }

      setTimeout(() => loaderStore.loader = false, 400);
      componentsStore.changePage(componentsStore.pages.done);
    } catch (ex) {
      return false;
    } finally {}
  };

  constructor() {
    makeAutoObservable(this);
  }
};

export default createContext(new ReceiptStore());
